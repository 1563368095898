/* Colors */
$black: #213640;
$black-seeThrough: rgba($black, 0.75);
$grey: #50545A;
$grey-offset: #3D5762;
$grey-offset-seeThrough: rgba($grey-offset, 0.5);
$blue-dark: #226CA8;
$blue: #0080C9;
$blue-light: #79BDE8; 
$red-dark: #DE1D24;
$red: #E23D36;
$red-light: #E85E58;
$orange-light: #E8C039;
$yellow: #f1b512;
$green-dark: #288E73;
$green: #159D69;
$green-light: #30AD87;
$white: #fff;
$white-seeThrough: rgba($white, 0.25);
$mayday-yellow: #fff200;

$under-blue: #cde3e7;
$blue-grad-1: #267C81;
$blue-grad-2: #70AFB9;
$deep-blue-grad-1: #1F6165;
$deep-blue-grad-2: #659FA8;

$orange: #F47920;
$ligher-orange: #F18912;
$under-orange:  #fde3cf;
$orange-grad-1: #F47920;
$orange-grad-2: #F1C695;
$deep-orange-grad-1: #D86B1D;
$deep-orange-grad-2: #EDBC99;

$background: #FFFAF5;