@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/global';
.loading {
	@include flex(center, center);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	&-circle {
		width: 0.4em;
		height: 0.4em;
		margin: 0 0.3em;
		background-color: #fff;
		border-radius: 50%;
	}
}
@for $i from 1 to 4 {
	.loading-circle:nth-child(#{$i}) {
		animation: blink 600ms ease #{300 * $i}ms alternate infinite;
	}
}

@keyframes blink {
	to {
		background-color: #ccc;
	}
}
