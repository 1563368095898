@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/global';
.ValidationMessage {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	width: 100%;
	display: block;
	background: #fff;
	@include box-shadow(0px, 3px, 16px, 1px, rgba(38, 124, 129, 0.24));
	z-index: 6;
	padding: 1em 1em 3em 1em;
	text-align: center;
	border-radius: 2em 2em 0 0;
	@include translate(0, 100%);
	@include transition(all, 0.5s, ease-in-out);
	&.showMessage {
		@include translate(0, 0);
	}
	.Btn {
		margin-top: 0.75em;
	}
}
