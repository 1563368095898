@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/global';
.Btn {
	border-radius: 3em;
	text-transform: uppercase;
	color: #fff;
	font-size: 1em;
	font-weight: bold;
	display: inline-block;
	padding: 0.7em 2.8em;
	@include box-shadow(0px, 3px, 16px, 1px, rgba(38, 124, 129, 0.24));
	&--orange {
		@include buttonColor($orange-grad-2, $orange-grad-1, 'hori');
		&:hover {
			@include box-shadow(0, 0, 0, 0, transparent);
			@include buttonColor($deep-orange-grad-2, $deep-orange-grad-1, 'hori');
		}
	}

	&--blue {
		@include buttonColor($blue-grad-2, $blue-grad-1, 'hori');
		&:hover {
			@include box-shadow(0, 0, 0, 0, transparent);
			@include buttonColor($deep-blue-grad-2, $deep-blue-grad-1, 'hori');
		}
	}
	&--red {
		background: #F12323;
		&:hover {
			@include box-shadow(0, 0, 0, 0, transparent);
			background: #f06969;
		}
	}

	&.Btn--isDisabled {
		@include buttonColor(#ebebeb, #acacac, 'hori');
		@include box-shadow(0, 0, 0, 0, transparent);
	}


}

body #root .Btn.bottom {
	margin-bottom:2em;
}