@-webkit-keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}
@keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}

@-webkit-keyframes textGlow {
	0% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
	50% {text-shadow: 0em 0em 0em white, 0em 0em 0em white;}
	100% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
}
@keyframes textGlow {
	0% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
	50% {text-shadow: 0em 0em 0em white, 0em 0em 0em white;}
	100% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
}

@-webkit-keyframes boxGlow {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
	50% {@include box-shadow(0, 0, 0em, 0em, #E23D36);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
}
@keyframes boxGlow {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
	50% {@include box-shadow(0, 0, 0em, 0em, #E23D36);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
}

@-webkit-keyframes wobble {
  0% {@include translateAndRotate();}
  1% {@include translateAndRotate(-0.1em, 0, 0, -2deg);}
  2% {@include translateAndRotate(0.2em, 0, 0, 1deg);}
  3% {@include translateAndRotate(-0.1em, 0, 0, -1deg);}
  4% {@include translateAndRotate(0.1em, 0, 0, 0.5deg);}
  5% {@include translateAndRotate(-0.1em, 0, 0, -0.5deg);}
	6% {@include translateAndRotate();}
	7% {@include translateAndRotate();}
}
@keyframes wobble {
  0% {@include translateAndRotate();}
  1% {@include translateAndRotate(-0.1em, 0, 0, -2deg);}
  2% {@include translateAndRotate(0.2em, 0, 0, 1deg);}
  3% {@include translateAndRotate(-0.1em, 0, 0, -1deg);}
  4% {@include translateAndRotate(0.1em, 0, 0, 0.5deg);}
  5% {@include translateAndRotate(-0.1em, 0, 0, -0.5deg);}
	6% {@include translateAndRotate();}
	7% {@include translateAndRotate();}
}