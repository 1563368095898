.pointer {
	cursor: pointer;
	@include no-select();
}

html {
	position: relative;
	font-size: 1.3vw; // 18px at screen width ~1380
}

body {
	position: relative;
	font-family: 'PT Sans', sans-serif;
	font-size: 1em;
	font-weight: normal;
	margin: 0;
	padding: 0;
	background: $background;
}

body {
	overscroll-behavior-y: contain;
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
}

html {
	height: -webkit-fill-available;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
	font-family: 'PT Sans', sans-serif;
}

button {
	background-image: none;
	-webkit-tap-highlight-color: transparent;
	@include no-select();
	&:focus {
		outline: 0;
	}
	&::-moz-focus-inner {
		border: 0;
	}
}

.centerButton {
	width: 100%;
	@include flex(center, center);
	padding-bottom: 2em;
}

ul,
ol {
	padding-inline-start: 1.5em;
	margin: 0;
}

input,
input:focus,
textarea,
textarea:focus {
	font-size: inherit;
	@include appearance(none);
}

.BackButton {
	position: absolute;
	fill: #fff;
	top: 0;
	left: 1em;
	width: 0.8em;
	height: 3.8em;
	z-index: 2;
	flex-basis: 1em;
	&:hover {
		fill: #ccc;
	}
}

.inner {
	padding: 1em;
}

.heavy {
	font-weight: bold;
	display: block;
}

.mobile {
	min-height: 100%;
	font-size: calc(18 * ((100vw / 370)));
}
.desktop {
	overflow-x: hidden;
	.Btn.fortsaet {
		position: fixed;
		top: 25vw;
		right: 3em;
		margin:0;
		padding: 0.7em 1em 0.7em 2em;
		@include flex(space-between, center);
		width: auto;
		&::after{
			content: '';
			height: 2em;
			display: inline-block;
			width: 1em;
			background-size: 100% 100%;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='34.983' viewBox='0 0 20 34.983'%3e%3cpath id='Icon_ionic-ios-arrow-back' data-name='Icon ionic-ios-arrow-back' d='M25.221,23.681,11.985,10.454a2.5,2.5,0,0,1,3.541-3.53l15,14.986a2.5,2.5,0,0,1,.073,3.447L15.536,40.448A2.5,2.5,0,0,1,12,36.917Z' transform='translate(-11.251 -6.194)' fill='%23fff'/%3e%3c/svg%3e ");
			margin-left: 1.3em;
		}
	}
}


// @media screen and (min-width: 320px) {
// 	html {
// 		font-size: calc(18 * ((100vw / 360)));
// 	}
// }

// @media screen and (min-width: 768px) {
// 	html {
// 		font-size: calc(18 * (100vh / 920));
// 	}
// }

// @media screen and (min-width: 512px) {
//   html {
//     font-size: 22.75px;
//     min-height: auto;
//   }
// }
