// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance: #{$value};
	appearance: #{$value};
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

// FLEX
@mixin flex($justify: '', $align: '', $direction: '') {
	display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box; // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox; // TWEENER - IE 10
	display: -webkit-flex; // NEW - Chrome
	display: flex; // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify: '', $align: '', $direction: '') {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	@include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify != '' {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align != '' {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};
	}
	@if $direction != '' {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

//all transforms
@mixin transforms ($transformations) {
	-ms-transform: $transformations;
	-webkit-transform: $transformations;
	transform: $transformations;
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// TRANSITION
@mixin transition($target, $seconds, $type) {
	-moz-transition: #{$target} $seconds #{$type}; /* Firefox */
	-webkit-transition: #{$target} $seconds #{$type}; /* WebKit */
	-o-transition: #{$target} $seconds #{$type}; /* Opera */
	transition: #{$target} $seconds #{$type}; /* Standard */
}

@mixin transition-delay($time) {
	-webkit-transition-delay: $time;
	-moz-transition-delay: $time;
	-o-transition-delay: $time;
	transition-delay: $time;
}

@mixin translate($x, $y: 0) {
	-ms-transform: translate($x, $y);
	-webkit-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-ms-transform: translate3d($x, $y, $z);
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin translateAndRotate($x: 0, $y: 0, $z: 0, $angle: 0) {
	-ms-transform: translate3d($x, $y, $z) rotate($angle);
	-webkit-transform: translate3d($x, $y, $z) rotate($angle);
	transform: translate3d($x, $y, $z) rotate($angle);
}

@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity='
		$procentOpacity ')'; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	input:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	input::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	input:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	textarea:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	textarea::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	textarea:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
	-webkit-tap-highlight-color: transparent;
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

/* FANCY SCROLLBAR Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
@mixin fancy-scrollbar(
	$width,
	$height,
	$backgroundColor,
	$borderRadius,
	$thumbWidth,
	$thumbHeight,
	$thumbColor
) {
	::-webkit-scrollbar {
		width: $width;
		height: $height;
	}
	::-webkit-scrollbar-track {
		background-color: $backgroundColor;
		border-radius: $borderRadius;
	}
	::-webkit-scrollbar-thumb {
		border-radius: $borderRadius;
		background: $thumbColor;
		width: $thumbWidth;
		height: $thumbHeight;
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background-color: transparent;
		border: 1px solid $thumbColor;
	}
}

@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/*Chrome, Safari and Edge*/
	&::-webkit-scrollbar {
		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/*Firefox*/
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

@mixin rotationStyle($style) {
	-webkit-transform-style: #{$style};
	-moz-transform-style: #{$style};
	transform-style: #{$style};
}

// BOX SHADOW
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

@mixin backgroundsDarken($c, $y) {
	background: $c;
	@for $i from 1 through $y {
		$k: 3%;
		$j: $i - 1;

		@if $i == 1 {
			&:nth-child(#{$i}) {
				background: $c;
			}
		} @else {
			&:nth-child(#{$i}) {
				background: darken($c, ($k * $j));
			}
		}
	}
}

@mixin backgroundsLighten($c, $y) {
	background: $c;
	@for $i from 1 through $y {
		$k: 3%;
		$j: $i - 1;

		@if $i == 1 {
			&:nth-child(#{$i}) {
				background: $c;
			}
		} @else {
			&:nth-child(#{$i}) {
				background: lighten($c, ($k * $j));
			}
		}
	}
}

@mixin backgroundsLightenInvert($c, $y) {
	background: $c;
	@for $i from -$y through -1 {
		$j: abs($i) - 1;
		$k: (100 / abs($y))/3;
		@if abs($i) == -$y {
			&:nth-child(#{abs($i)}) {
				background: $c;
			}
		} @else {
			&:nth-child(#{$y - abs($i)}) {
				background: lighten($c, ($k * $j));
			}
		}
	}
}

@mixin blurBackground($blurPixels) {
	-webkit-backdrop-filter: blur($blurPixels);
	backdrop-filter: blur($blurPixels);
}

@mixin defaultPage() {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: left;
}

@mixin contain($w: 900px) {
	@media (min-width: 1080px) {
		padding-left: calc((100% - #{$w}) / 2);
		padding-right: calc((100% - #{$w}) / 2);
	}
	@media (max-width: 1079px) {
		padding-left:2em;
		padding-right: 2em
	}
}

@mixin gradient($start, $end, $startpos: 0%, $endpos: 100%, $direction: 'vert') {
	@if $direction == 'vert' {
		background: $start; /* Old browsers */
		background: -moz-linear-gradient(top,  $start $startpos, $end $endpos); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  $start $startpos,$end $endpos); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  $start $startpos,$end $endpos); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$end}',GradientType=0 ); /* IE6-9 */
	}
	@if $direction == 'hori' {
		background: $start; /* Old browsers */
		background: -moz-linear-gradient(left,  $start $startpos, $end $endpos); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  $start $startpos,$end $endpos); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  $start $startpos,$end $endpos); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$end}',GradientType=1 ); /* IE6-9 */
	}

}

@mixin gradientBottomFix($start, $end) {
	background: $start; /* Old browsers */
	background: -moz-linear-gradient(top,  $start 0%, $end 98%, #fff 98%); /* FF3.6-15 */
	background: linear-gradient(to bottom,  $start 0%, $end 98%, #fff 98%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background: -webkit-linear-gradient(top,  $start 0%, $end 98%, #fff 98%); /* Chrome10-25,Safari5.1-6 */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start}', endColorstr='#{$end}',GradientType=0 ); /* IE6-9 */
}

@mixin buttonColor($start: #F1CBB0, $end: #F47920, $direction: 'hori'){
	@if $direction == 'vert' {
		@include gradient($start, $end, 0%, 100%, 'vert');
	}
	@if $direction == 'hori' {
		@include gradient($start, $end, 0%, 100%, 'hori');
	}
}

@mixin endHeader($bgcolor: #FFFAF5) {
	display: block;
	width: 100%;
	position: relative;
	overflow: hidden;
	.headerBottom, .headerBottom2{
		min-width: 101%;
		height: auto;
		position: absolute;
		bottom:-2px;
		left: 50%;
		@include translate(-50%);
	}
	#headerbackground{
		fill:$bgcolor;
	}
}
